import { useState, useRef } from 'react';
import { QrScanner } from 'react-qrcode-scanner';
import { MdOutlineQrCodeScanner, MdAssignmentAdd } from 'react-icons/md';

const ScanQR = (props) => {
  const { qrItemList, setQrItemList } = props;
  const [qrActive, setQrActive] = useState(false);
  const [manualInput, setManualInput] = useState('');
  const inputRef = useRef(null);
  // console.log(manualInput);

  const handleScan = (value) => {
    console.log(value);
    const qrItem = {
      title: value,
      qty: 1,
    };

    if (qrItemList.length === 0) {
      // Если qrItemList пустой, добавляем новый объект
      setQrItemList([qrItem]);
    } else {
      const isValueExists = qrItemList.some((item) => item.title === value);
      if (!isValueExists) {
        // Используем value как title и value для нового объекта
        setQrItemList((prevQrItem) => [...prevQrItem, qrItem]);
      } else {
        // Увеличиваем количество, если значение уже существует
        setQrItemList((prevQrItem) =>
          prevQrItem.map((item) =>
            item.title === value ? { ...item, qty: Number(item.qty) + 1 } : item
          )
        );
      }
    }
    setQrActive(false);
  };

  const handleError = (error) => {
    console.log({ error });
  };

  const handleInputChange = (e) => {
    const newManualInput = e.target.value;
    setManualInput(newManualInput.toLowerCase());
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addManually();
    }
  };

  const addManually = () => {
    if (manualInput.trim() === '') {
      return;
    }
    const qrItem = {
      title: manualInput,
      qty: 1,
    };

    if (qrItemList.length === 0) {
      setQrItemList([qrItem]);
    } else {
      const isValueExists = qrItemList.some(
        (item) => item.title === manualInput
      );
      if (!isValueExists) {
        setQrItemList((prevQrItem) => [...prevQrItem, qrItem]);
      } else {
        setQrItemList((prevQrItem) =>
          prevQrItem.map((item) =>
            item.title === manualInput
              ? { ...item, qty: Number(item.qty) + 1 }
              : item
          )
        );
      }
    }
    setManualInput(''); // Очистить поле ввода после добавления
    if (inputRef.current) {
      inputRef.current.blur(); // Снять фокус с поля ввода
    }
  };

  return (
    <section className={qrActive ? 'scanQR active' : 'scanQR'}>
      <div className="scanQR_header">
        <div className="scanQR_header-input">
          <input
            type="text"
            value={manualInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            ref={inputRef}
          />
          <button className="scanQR_btn" onClick={addManually}>
            <MdAssignmentAdd />
          </button>
        </div>
        <button
          className="scanQR_btn"
          onClick={() => {
            setQrActive((prevQrActive) => !prevQrActive);
          }}
        >
          <MdOutlineQrCodeScanner />
        </button>
      </div>

      {qrActive && (
        <QrScanner
          className="QRscreen"
          delay={500}
          onError={handleError}
          onScan={handleScan}
          aspectRatio="1:1"
          flipHorizontally="true"
          showViewFinder="true"
        />
      )}
    </section>
  );
};

export default ScanQR;
