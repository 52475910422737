import { useState, useRef } from 'react';

const ListItem = (props) => {
  const { setQrItemList, qrItem } = props;

  const { title, qty } = qrItem;
  const [isEditing, setIsEditing] = useState(false);
  const [newQty, setNewQty] = useState(qty);
  const inputRef = useRef(null);

  const handleQtyClick = () => {
    setIsEditing(true);
  };

  const handleQtyChange = (e) => {
    setNewQty(e.target.value);
  };

  const handleQtyBlur = () => {
    setQrItemList((prevQrItemList) =>
      prevQrItemList.map((item) =>
        item.title === title ? { ...item, qty: newQty } : item
      )
    );
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Остановить распространение события
      handleQtyBlur();
      if (inputRef.current) {
        inputRef.current.blur(); // Удалить фокус с input
      }
    }
  };

  return (
    <div className="ListItem">
      <div className="ListItem_title">{title}</div>
      {isEditing ? (
        <input
          type="number"
          value={newQty}
          onChange={handleQtyChange}
          onBlur={handleQtyBlur}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          autoFocus
          className="ListItem_qty"
        />
      ) : (
        <div onClick={handleQtyClick} className="ListItem_qty">
          {newQty}
        </div>
      )}
    </div>
  );
};

export default ListItem;
