import * as XLSX from 'xlsx';

const exportToExcel = (data, fileName) => {
  // Создаем новый рабочий лист из данных
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Создаем новую рабочую книгу и добавляем в неё рабочий лист
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Генерируем бинарный Excel файл
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export default exportToExcel;
