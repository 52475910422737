import { useState } from 'react';
import {
  MdMenu,
  MdOutlineClear,
  MdAlternateEmail,
  MdSimCardDownload,
} from 'react-icons/md';

const Menu = (props) => {
  const { setEmail, handleSubmit, handleXlsxExport } = props;
  const [submenu, setSubmenu] = useState(false);

  const toggleSubmenu = () => {
    setSubmenu(!submenu);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    console.log(e.target.value);
  };

  return (
    <header className="Menu">
      <div className="Menu_btns">
        <button className="Menu_burger" onClick={toggleSubmenu}>
          {submenu ? <MdOutlineClear /> : <MdMenu />}
        </button>
        <div className="Menu_actionBtns">
          <button className="Menu_export" onClick={handleXlsxExport}>
            <MdSimCardDownload />
          </button>
          <button className="Menu_submit" onClick={handleSubmit}>
            <MdAlternateEmail />
          </button>
        </div>
      </div>

      <section className={submenu ? 'submenu active' : 'submenu'}>
        {/* <span>Email</span> */}
        <fieldset>
          <legend>Destinazione email</legend>
          <input
            name="email"
            type="text"
            className="submenu_email"
            onChange={handleEmailChange}
            placeholder="example@mail.com"
          ></input>
        </fieldset>
      </section>
    </header>
  );
};

export default Menu;
