import ScanQR from './components/ScanQR';
import ListItem from './components/ListItem';
import Menu from './components/Menu';
import { v4 as uuidv4 } from 'uuid';
import './App.css';
import { useState } from 'react';
import exportToExcel from './utils/exportToExsel';

function App() {
  const [qrItemList, setQrItemList] = useState([]);
  const [email, setEmail] = useState('');
  // console.log(qrItemList);

  const handleXlsxExport = () => {
    if (qrItemList.length > 0) {
      // Форматируем данные для экспорта
      const formattedData = qrItemList.map((item) => ({
        item: item.title,
        quantity: item.qty,
      }));

      exportToExcel(formattedData, 'Scanned');
    } else {
      console.error('No QR items to export');
    }
  };

  const handleSubmit = () => {
    if (email && qrItemList.length > 0) {
      const data = {
        email: email,
        qrItems: qrItemList,
      };
      console.log(data);
      fetch('../send.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 'success') {
            console.log('sent successfully');
            setQrItemList([]);
          } else {
            console.log('error while sending ', res.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error('enter email and update QR elements before dispatch');
    }
  };

  return (
    <div className="App">
      {/* <Menu setEmail={setEmail} handleSubmit={handleSubmit} /> */}
      <Menu
        setEmail={setEmail}
        handleSubmit={handleSubmit}
        handleXlsxExport={handleXlsxExport}
      />

      <fieldset className="qrList">
        <legend>Lista</legend>
        <ul>
          {qrItemList &&
            qrItemList.map((item) => {
              return (
                <li key={uuidv4()}>
                  <ListItem qrItem={item} setQrItemList={setQrItemList} />
                </li>
              );
            })}
        </ul>
      </fieldset>

      <ScanQR
        qrItemList={qrItemList}
        setQrItemList={setQrItemList}
        // qrActive={qrActive}
        // setQrActive={setQrActive}
        // handleScan={handleScan}
        // handleError={handleError}
      />
    </div>
  );
}

export default App;
